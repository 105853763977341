// export const domain = 'https://c360-api-test.azurewebsites.net';
// export const nodeDomain = 'https://c360-api-test-node.azurewebsites.net';

const clean = value => {
  const FALSY_VALUES = ['', 'null', 'false', 'undefined'];
  if (!value || FALSY_VALUES.includes(value)) {
    return undefined;
  }
  return value;
};

export const enableGlobalLandingPage =
  process.env.REACT_APP_ENABLE_GLOBAL_PAGE === 'true';

const publicSite =
  clean(process.env.REACT_APP_ALTERNATE_PUBLIC_SITE_DOMAIN) ||
  process.env.REACT_APP_PUBLIC_SITE_DOMAIN;
const c360BackendDomain = 
  clean(process.env.REACT_APP_ALTERNATE_ROUTER_DOMAIN) ||
  process.env.REACT_APP_ROUTER_DOMAIN;
export const domain = {
  // auth: 'https://c360-api-test-node.azurewebsites.net',
  env: process.env.REACT_APP_CUSTOM_NODE_ENV,
  router: c360BackendDomain, // 'http://localhost:8086', // 'https://c360-router.azurewebsites.net',
  strapi:
    clean(process.env.REACT_APP_ALTERNATE_STRAPI_DOMAIN) ||
    process.env.REACT_APP_STRAPI_DOMAIN, // 'https://c360-strapi.azurewebsites.net', //
  // strapi: 'http://localhost:1337', // // 'https://c360-strapi.azurewebsites.net', //
  pwa:
    clean(process.env.REACT_APP_ALTERNATE_PWA_DOMAIN) || process.env.REACT_APP_PWA_DOMAIN,
  publicSite,
  website: publicSite,
  // publicSite: 'http://localhost:3000',
  subdomains: {
    auth: c360BackendDomain, // 'http://localhost:8083',
    accounts: c360BackendDomain, // c360BackendDomain, // 'http://localhost:8083', // c360BackendDomain, // 'http://localhost:8084', // 'http://localhost:8083',  //  process.env.REACT_APP_ROUTER_DOMAIN, // 'https://a029-190-213-94-12.ngrok.io', // 'http://localhost:8081', // "https://api.test.c360.myggonline.app/test",
    customers: c360BackendDomain, // process.env.REACT_APP_ROUTER_DOMAIN, // 'http://localhost:8084',
    'account-services': c360BackendDomain, // 'http://localhost:8080'
    cms: c360BackendDomain, // 'http://localhost:8082', 'http://localhost:8082', //
    webstar: c360BackendDomain,
  },
  support:
    clean(process.env.REACT_APP_ALTERNATE_SUPPORT_DOMAIN) ||
    process.env.REACT_APP_SUPPORT_DOMAIN,
};

export const newStorageVersion = 48;

export const facebookAuthPath = `${domain.router}/passport/facebook`;
// export const facebookAuthPath = 'https://c360-auth.azurewebsites.net/facebook';
export const googleAuthPath = `${domain.router}/auth/google`;
export const apiCredentials = {
  subscriptionKey: '3d0934c2c0b741dc91b70118a3cd4705',
};

export const allowCaching = true;
export const disableRecaptchaEnv = false;
export const introVideoUrl = '/videos/sample_video.mp4';
export const statusOk = 'ok';
export const statusError = 'error';
export const statusSuccess = 'success';

export const customerTimeOut = 60000 * 3; // 20000;
export const agentTimeOut = 20000 * 3 * 5;

export const drawerWidth = 250;
export const maxAppWidth = 1920;

export const persistKey = '_persisted_at';
export const initialEncryptKey = 'guardian_group_c360_app';

export const signInOperation = 'signIn';
export const signUpOperation = 'signUp';
export const resetOperation = 'reset';
export const facebookSignInOperation = 'fbSignIn';
export const facebookSignUpOperation = 'fbSignUp';
export const googleSignInOperation = 'googleSignIn';
export const googleSignUpOperation = 'googleSignUp';
export const allSignUpOperation = 'allSignUp';

export const authFooterHeight = '5px';
export const authHeaderHeight = '10vh';

export const mainFooterHeight = '0px'; // '167px';
export const mainHeaderHeight = '65px';
export const dutchMainHeaderHeight = '144px';
// export const mainMobileHeaderHeight = '100px';
export const mainMobileHeaderHeight = '60px';
export const mainMobileNotAuthHeaderHeight = '60px';
export const brandLogoHeight = '60px';
export const bottomNavHeight = '60px';

export const getSessionStorageKey = 'getSessionStorage';
export const removeSessionStorageKey = 'removeSessionStorage';
export const sessionStorageKey = 'sessionStorage';

export const appThemeNameStorage = 'appThemeName';
export const accessTokenStorage = 'accessToken';
export const customThemeStorage = 'customTheme';
export const networkStatusStorage = 'networkStatus';
export const alpha2Storage = 'alpha2';
export const manuallyChosenAlpha2Storage = 'manuallyChosenAlpha2';
export const languageStorage = 'language';
export const templateStorage = 'templateName';
export const countryVerificationStorage = 'countryVerification';
export const countryVerificationStorageType = 'local';
export const countryVerificationExpireTimeInDays = 1;

export const renewalListing = 'Renewal Listing';
export const transactionBordereau = 'Transaction Bordereau';
export const investmentType = 'investment';
export const insuranceType = 'insurance';
export const insuranceDetailsType = 'insuranceDetails';
export const investmentDetailsType = 'investmentDetails';
export const insuredItemType = 'insuredItemType';

export const motorLob = 'motor';
export const healthLob = 'health';
export const lifeLob = 'life';
export const investmentLob = 'investments';
export const investmentTypeMf = 'mf';
export const investmentTypePw = 'pw';
export const pensionLob = 'pension';
export const homeLob = 'home';
export const otherLob = 'other';
export const allLob = 'all';

export const ggil = 'ggil';

export const netBillingType = 'net';
export const grossBillingType = 'gross';

export const usCurrency = 'USD';

export const loadTransactionCount = 30;
export const loadClaimsCount = 6;
export const loadRequestsCount = 6;
export const loadQuotesCount = 6;
export const loadInvestmentCount = 3;
export const loadReceiptsCount = 6;
export const loadInsuredItemsCount = 6;

export const precertIndexName = 'precerts';
export const pendingPrecertsIndexName = 'pendingPrecerts';
export const registeredPrecertIndexName = 'registeredPrecerts';
export const claimsIndexName = 'claims';
export const partialClaimsIndexName = 'partiallyFilledClaims';
export const requestsIndexName = 'requests';
export const quotesIndexName = 'quotes';
export const clientsIndexName = 'clients';
export const reportsIndexName = 'reports';
export const clientPoliciesIndexName = 'clientPolicies';
export const receiptsIndexName = 'receipts';
export const insuredItemsIndexName = 'insuredItems';
export const insuranceIndexName = 'insurances';
export const cardItemsListSelectedIndexName = 'cardItemsListSelectedIndices';
export const digitalSalesName = 'digitalSales';
export const portfolioIndexName = 'portfolio';
export const pendingRequestIndexName = 'pendingRequests';
export const newRequestIndexName = 'newRequests';

export const linkType = 'link';
export const dropdownLinkType = 'non_link';
export const dividerType = 'divider';
export const modalLinkType = 'modal';

export const applyLinkTheme = 'applyLinkTheme';

export const defaultIntlId = 'test';

export const migrationSourceGGIL = 'GGIL';
export const migrationSourceGLOC = 'GLOC';

export const searchKey = 'search';

export const agentType = 'agent';
export const customerType = 'customer';
export const agentBRK = 'BRK';
export const agentSAL = 'SAL';
export const agentBIN = 'BIN';
export const groupPlanType = 'group';
export const corporateType = 'corporate';
// export const storageRoot = 'persist:root';

export const defaultAlpha2 = 'RES';
export const resAlpha2 = 'RES';
export const ttAlpha2 = 'TT';

export const consentKey = 'consent';
export const customConsentKey = 'customConsent';
export const acceptAllConsentValue = 'acceptAll';
export const acceptCustomConsentValue = 'acceptCustom';
export const rejectConcentValue = 'reject';

export const iosInstallPromptedAtStorage = 'iosInstallPromptedAt';
export const webInstallPromptedAtStorage = 'webInstallPromptedAt';

export const consentAcceptExpireTimeHrs = 365 * 24;
export const consentRejectExpireTimeHrs = 365 * 24;
export const userSettingsExpireTimeHrs = 365 * 24;
export const rsExpiryTimeHrs = 0.15;
export const campaignExpiryTimeSeconds = 60 * 60 * 3;

export const AGENT_ROLE = 'agent';
export const SUB_AGENT_ROLE = 'sub-agent';

export const cookiePolicyVersion = 3;
export const cookiePolicyVersionKey = 'cookiePolicyVersion';

export const calcOperators = {
  Add: '+',
  Subtract: '-',
  Multiple: '*',
  Divide: '/',
  Mod: '%',
};
export const titleDropdownOptions = ['Mr.', 'Mrs.', 'Ms.', 'Dr.'];

export const MOBILE_REGEX = /^\+[0-9]+/;
export const MOBILE_MESSAGE = 'Please provide a valid phone number, E.g. +59994563412';
export const PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!_%*.#()?&-])[A-Za-z\d@$!_%*.#()?&-]{10,20}$/;
export const PASSWORD_MESSAGE =
  'Password should be between 10 and 20 characters long, have at least one upper case letter, one lower case letter, one number and at least one of the following special characters   @$!_%*.#()?&-';

export const searchOptionTypes = {
  resources: 'resources',
  holdings: 'holdings',
  onlineForms: 'online-forms',
  pdfForms: 'pdf-forms',
  products: 'products',
  portfolio: 'portfolio',
  notifications: 'notifications',
  claims: 'claims',
  quotes: 'quotes',
  applications: 'applications',
  receipts: 'receipts',
  clientPolicies: 'client-policies',
  clients: 'clients',
  pendingRequests: 'pending-requests',
};

export const statusMatured = 'Matured';

export const motorAccidentType = 'Motor Accident';
export const thirdPartyType = 'Third Party';
export const windscreenType = 'Windscreen';
export const keyReplacementType = 'Key Replacement';

export const owner = 'owner';
export const beneficiary = 'beneficiary';
export const insured = 'insured';

export const premiumPaymentType = 'premium';
export const loanPaymentType = 'loan';
export const aplPaymentType = 'apl';
export const customPaymentType = 'custom';
export const recurringPaymentType = 'recurring';
export const manualPaymentType = 'manual';

export const outstandingGroupName = 'outstanding';
export const advanceGroupName = 'advance';

export const privateType = 'private';
export const commercialType = 'commercial';
export const brokerType = 'broker';
export const productTypeMore = 'More';

export const dutchTemplate = 'dutch';
export const dutchDateFormat = 'dd/MM/yyyy';
export const customerMatchTypes = {
  FULL_NAME_DOB_MATCH: 'FULL_NAME_DOB_MATCH',
  FULL_EMAIL_MATCH: 'FULL_EMAIL_MATCH',
  FULL_MOBILE_NUMBER_MATCH: 'FULL_MOBILE_NUMBER_MATCH',
  PARTIAL_MATCH: 'PARTIAL_MATCH',
  PARTIAL_FIRSTNAME_DOB_MATCH: 'PARTIAL_FIRSTNAME_DOB_MATCH',
  PARTIAL_FIRSTNAME_LASTNAME_MATCH: 'PARTIAL_FIRSTNAME_LASTNAME_MATCH',
  NO_MATCH: 'NO_MATCH',
  COMPANY_MATCH: 'COMPANY_MATCH',
  NEW_CUSTOMER: 'NEW_CUSTOMER',
};

export const dutchSavedResponseKey = 'premiumResponse';

export const overviewMessage =
  'Based on your answers we need to check your request for offering you a customized premium. Please keep entering your information and  we’ll get back to you.';

export const dutchEvaluationsType = {
  personInspection: 'personInspection',
  carInspection: 'carInspection',
  legalCheck: 'legalCheck',
  blocked: 'blocked',
};

export const installmentPeriod = {
  yearly: 'Yearly',
  quarterly: 'Quarterly',
  half_yearly: 'Half Yearly',
  monthly: 'Monthly',
};

export const broker = {
  ZI: 'ZI',
};

export const dutchInusranceProductType = {
  WA: 'wa',
  WA_PLUS: 'waPlus',
  CASCO: 'casco',
  CASCO_1: 'casco1',
  CASCO_2: 'casco2',
  CASCO_3: 'casco3',
  FIFTY_PLUS: 'fiftyPlus',
  PRIVATE_HOME: 'premium',
  PRIVATE_BASIC_HOME: 'basic',
  LIABILITY: 'liability',
};

export const dutchCountries = [
  { value: 'AW', name: 'Aruba' },
  { value: 'BQ-BO', name: 'Bonaire' },
  { value: 'CW', name: 'Curaçao' },
  { value: 'NL', name: 'Netherlands' },
  { value: 'BQ-SA', name: 'Saba' },
  { value: 'BQ-SE', name: 'Sint Eustatius' },
  { value: 'SX', name: 'Sint Maarten' },
];
export const inceptionYearOfDutchGG = 1904;
export const yearsSinceInception = new Date().getFullYear() - inceptionYearOfDutchGG;
